// ====================================
// Consts
// ====================================


const content = document.getElementsByClassName("gc-content")[0];
const text = document.getElementsByClassName("gc-text")[0];
const logo = document.getElementsByClassName("gc-image")[0];
const esButton = document.getElementsByClassName("gc-es-button")[0];
const spaceholder = document.getElementsByClassName("spaceholder")[0];

const additionalSpace = 700;
const textHeight = additionalSpace+text.offsetHeight;

// ====================================
// Methods
// ====================================

const initMenu = (menuName) => {
    const menu = document.getElementsByClassName("menu "+menuName)[0];
    const menuButton = document.getElementsByClassName("btn-"+menuName)[0];
    const menuContainer = menu.getElementsByClassName("menu-container")[0];
    const menuCloseButton = menu.getElementsByClassName("btn-close")[0];
    
    const closeMenu = ()=> {
        menuContainer.classList.remove("slide-left-menu");
        menuContainer.classList.add("slide-back-left-menu");
    
        setTimeout(() => {
            menu.classList.remove("show");
            menuContainer.classList.remove("slide-back-left-menu");
        }, 500);
    };

    menuButton.addEventListener('click', ()=> {
        menu.classList.add("show");
        menuContainer.classList.add("slide-left-menu");
    });

    menuCloseButton.addEventListener('click', ()=> {
        closeMenu();
    });
};

// ====================================
// Events
// ====================================

window.addEventListener('load', ()=> {
    spaceholder.style.height = textHeight+"px";
});

window.addEventListener('scroll', () => {
    let scrollTop = document.documentElement.scrollTop;


    content.style.transform = "translateY(-"+(scrollTop-content.offsetTop)+"px)";

    if(scrollTop > text.offsetHeight) {
        logo.style.transform = "translateY(-"+(scrollTop-text.offsetHeight)+"px)";
    } else {
        logo.style = "";
    }

    if(scrollTop > spaceholder.offsetHeight-additionalSpace) {
        esButton.style.color = "#fff";
    } else {
        esButton.style.color = "";
    }
});


esButton.addEventListener('click', ()=> {
    window.location.href = "https://www.emmrich-solutions.com";
});

// ====================================
// Code
// ====================================

initMenu("imprint");
initMenu("privacy-policy");